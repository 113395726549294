.modelsMenuInline {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    max-height: calc(100% - 20px - env(safe-area-inset-top));
}

.modelsMenuInline .chatGptModelsMenu {
    margin-top: 0px;
    width: 100%;
}


.legacy {
    text-decoration: line-through;
}
