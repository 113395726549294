.uiButton {
    height: 40px;
    cursor: pointer;
    color: rgb(250, 250, 250);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 13px;
    height: 35px;
    cursor: pointer;
}

.uiButtonIcon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.uiButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiButtonLabel {
    color: rgb(250, 250, 250);
    font-size: 13px;
    white-space: nowrap;
}

.uiToggleButton {
    height: 40px;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uiToggleButtonSelected {
    background: rgb(184, 38, 26);
    color: white;
}

.uiToggleButtonSelected .uiToggleButtonLabel {
    visibility: visible;
}

.uiToggleButtonLabel {
    width: 100%;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    padding-left: 14px;
    visibility: hidden;
}

@media not all and (pointer: coarse) {
.uiToggleButton:hover {
    background:  #B6EEFF;
    color: #666666;
}

.uiToggleButtonSelected:hover {
    background: #c40a0a;
    color: white;
}

.uiToggleButtonSelected:hover .uiToggleButtonLabel {
    color: white !important;
}

.uiToggleButtonSelected:hover svg {
    fill: white !important;
}

.uiToggleButton:hover .uiToggleButtonLabel {
    visibility: visible;
    color: #666666;
}

.uiToggleButton:hover svg {
    fill: #666666;
}
}

.uiToggleButton svg {
    height: 40px;
    width: 40px;
}

.uiToggleButton .uiIcon {
    width: 40px;
    padding-right: 10px;
}
