.chatGPT {
    font-size: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100%);
}

.benxtAppNative .chatGPT {
    height: calc(100% - env(safe-area-inset-bottom)  - 30px);
}

.chatGPT .template {
    width: calc(100% - 0px);
}

.chatGPT .template .keyboardCode {
    width: calc(100% + 40px);
    transform: translate(0px, 0);
}

.chatGPT .keyboardEditDocumentText {
    width: calc(100% - 80px);
}

.chatGPT .keyboardEditCode {
    transform: none;
}

.keyboardHeaderButtonCancel {
    visibility: hidden;
}

.benxtAskAppOnly .keyboardHeader .inputControlKeyboardButton1 {

}

.chatGPTKeyboard {
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 40px;
    height: 100px;
}

.chatGPTKeyboard .keyboardHeader {
    display: none;
}

.chatGPTKeyboard .keyboardWindow {
    overflow-y: visible;
    min-height: 160px;
}

.chatGPTKeyboard .keyboardInputContainer {
    display: none;
}

.chatGPTKeyboard .keyboardInstructionListContainer {
    background: rgb(0, 26, 25);
}

.benxtAppDesktop .chatGPT {
    font-size: 14px;
}

.chatGPT .inputControlTopRow {
    display: none;
}

.chatGPTInput {
    position: absolute;
    bottom: 5px;
    width: 100%;
}

.chatGPTInputWithMenu .inputControlInputEditor {
    width: calc(100% - 40px);
}

.chatGPT .uiChatMarginBottom {
    transform: translate(0, 40px);
    display: flex;
    width: 100%;
    min-width: 100%;
    margin-top: 0px;
    height: calc(100% - (132px - 42px)); 
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.chatGPTNoInput .uiChatMarginBottom {
    transform: none;
    height: calc(100%);
}

.chatGPT .uiChatMarginBottomSearchField {
    height: calc(100% - (132px - 42px)); 
}

.chatGPTNoInput .uiChatMarginBottomSearchField {
   height: calc(100%); 
}
.chatGPT .chatMessagesSliderEnabled .uiChatMarginBottom {
    width: 50%;
    min-width: 50%;
}

.chatGptChatMessageHeaderTopic {
    color: #888888;
}

.chatGptChatMessageHeaderTopic2 {
    font-weight: 550;
    height: 40px;
    display: flex;
    align-items: center;
}

.chatGPT .uiChatMarginBottomThread {
    justify-content: space-between;
}

.chatGPT .keyboardEditDocument {
    margin-top: 0;
}

.chatGPT .keyboardHeader {
    margin-bottom: 5px;
}

.chatGPT .uiChatMessages {
    padding-top: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
}

.chatGPT .uiChatMessagesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 6px;
}

.chatGPT .keyboardEditInstruction {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}

.chatGPT .aiUser .keyboardEditInstructionText {

}


.chatGPTMessageReactions {
    width: 100%;
    transform: translate(40px, 0);
    letter-spacing: 4px;
    padding-bottom: 2px;
}

.chatGptSearchFieldContainer {
    background: rgb(0, 26, 25);
    top: 0px;
    left: 0;
    z-index: 10;
    //padding-top: 6px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chatGptSearchFieldContainer .inputControlMiddleRowContainer {
    position: relative;
    flex-grow: 0;
    display: flex;
    width: auto;
}

.chatGPTInput .inputControlMiddleRowContainer {
    position: relative;
    flex-grow: 0;
    display: flex;
    width: auto;
}

.chatGptSearchFieldContainer .keyboardAddButton {
    position: relative;
}

.chatGptQuestions .keyboardAddButton {
    position: relative;
}

.chatGptSearchFieldContainer .keyboardInstructionInput {
    min-height: auto;
    height: 40px;
}

.chatGPT .keyboardMenuAutocompletes {
    position: absolute;
    top: 81px;
    right: 0;
    left: auto;
    bottom: auto;
}

.chatGPT .chatGPTQuestionsAutocomplete {
    width: calc(100vw - 10px);
    justify-content: flex-end;
}

.benxtAppDesktop .chatGPTQuestionsAutocomplete {
    width: calc(600px - 10px);
}

.chatGPT .keyboardMenuAutocompletes {
    row-gap: 1px;
}

.chatGPT .keyboardMenuAutocompletes.chatGPTQuestionsAutocomplete .keyboardMenuItemAutocomplete {
    background: rgb(0, 26, 25);
    overflow-y: auto;
    max-height: calc(100% - 80px - env(safe-area-inset-top) - 10px);
}

.chatGPT .keyboardInstructionInput .inputControlBottomRow {
    display: none;
}

.chatGPT .keyboardMenuAutocompletes .keyboardMenuItem {
    background: rgb(49, 47, 102);
}

.chatGptThreads {
    width: 100%;
    position: relative;
    z-index: 10;
    top: 0;
    max-width: 40px;
}

.chatGptQuestions {
    width: 100%;
    position: relative;
    z-index: 10;
    top: 0;
}
.chatGptThreadsMenuContainer {
    width: 100%;
    height: 100%;
}

.chatGptThreadsMenu {
    background: rgb(0, 26, 25);
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    overflow-y: auto;
    height: calc(100% - 225px);
    width: calc(100%);
}

.benxtAppMobile .chatGptThreadsMenu {
   height: calc(100% - 225px - env(safe-area-inset-top));
 }

.modelsMenuForce {
    width: 100%;
}

.chatGPTModelsMenuTitle {
    color: #e7e7e7;
    padding: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    font-weight: 500;
    background: rgb(181, 67, 26);
    align-items: center;
    position: absolute;
    top: -50px; 
}

.chatGPTModelsMenuTitle .simpleIcon {
    padding-right: 5px;
}


.chatGptModelsMenu {
    background: rgb(0, 26, 25);
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: calc(min(100vw, 600px) - 10px);
    margin-top: -8px;
    overflow-x: hidden;
    overflow-y: auto;
}


.chatGptThreadsMenuOptions {
    display: flex;
    column-gap: 1px;
    background: rgb(49, 47, 102);
    flex-grow: 0;
    width: auto;
    overflow-x: auto;
}


.chatGptThreadsVendorOptions {
    flex-grow: 0;
    display: flex;
    overflow-x: auto;
    max-width: 100%;
    width: auto;
    column-gap: 1px;
    background: rgb(49, 47, 102);
}

.chatGptThreadsMenuOptions .keyboardCheckboxLabel {
    justify-content: flex-end;
    padding-right: 15px;
}

.chatGptThreadsMenuOptionsContainer {
    background: rgb(49, 47, 102);
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 1px;
}

.chatGptQuestionsMenu {
    background: rgb(0, 26, 25);
    position: absolute;
    bottom: calc(80px + env(safe-area-inset-bottom) + 6px);
    padding-bottom: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 80px - env(safe-area-inset-bottom) - 10px);
    width: calc(100% - 10px);
    left: 5px;
    z-index: 20;
    min-height: 45px;
}


.chatGptChatMessageHeader {
    color: #c7c7c7;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 15px;
    padding-bottom: 5px;
}

.benxtAppDesktop .chatGptChatMessageHeader {
    font-size: 14px;
}

.chatGptChatMessageHeader.chatMessageFromUser {
    background: rgb(0, 26, 25);
}

.chatGptChatMessageHeader.chatMessageFromGpt {

}

.chatGptChatMessageTimestamp {
    font-size: 15px;
    white-space: nowrap;
    padding-left: 10px;
    color: #888888;
}

.chatGPT .keyboardAddButton {
    right: auto;
    left: 0;
}

.benxtAppSafari .chatGPT .keyboardAddButton {
    box-sizing: border-box;
}


.benxtAppDesktop .chatGptChatMessageTimestamp {
    font-size: 14px;
}


.chatMessagesSlider {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
}

.chatMessagesSliderEnabled {
    overflow-x: auto;
    min-width: 200%;
}

.chatMessagesSliderContainer {
    display: flex;
    width: 100%;
    height: calc(100% - 135px);
    flex-direction: column;
    justify-content: flex-end;
}

.chatGPTNoInput .chatMessagesSliderContainer {
    height: calc(100% - 10px);
    margin-bottom: 10px;
}

.chatMessagesBack {
    position: absolute;
    top: 0px;
}

.benxtAppDesktop .chatMessagesBack.keyboardMenuItem {
    font-size: 14px;
}

.chatMessagesBackBusy .keyboardMenuItemIconRight {
    position: absolute;
    right: 0;
}

.chatGptThreadMenuThreads {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
}

.chatGptThreadMenuThreads .keyboardMenuItem {
    display: flex;
}

.chatGptQuestionMenuQuestions {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
    max-width: 100%;
    z-index: 1;
}

.chatGptQuestionMenuQuestions .keyboardMenuItem {
    font-size: 15px;
}

.benxtAppDesktop .chatGptQuestionMenuQuestions .keyboardMenuItem {
    font-size: 15px;
}
.chatGptThreadMenuThreads .keyboardMenuItem {
    font-size: 15px;
}

.benxtAppDesktop .chatGptThreadMenuThreads .keyboardMenuItem {
    font-size: 14px;
}

.copyAISaid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}

.retryAISaid .uiOKCancelOK {
    background: rgb(181, 67, 26) !important;
}

.bottomRowButtons .uiOKCancelOK {
    background: #444444 !important;
}

.retryAISaid {
}

.aiCheckButton svg path {
    fill: #e7e7e7 !important;
}

.chatGptChatMessage {
    width: 100%;
}

.chatGptChatMessage .swiper {
    width: 100%;
}

.judgementSwiper {
    background: #0f2733;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.judgementSwiper .swiper {
    width: 100%;
}


.chatMessagesSliderContainer .swiper {
    width: 100%;
    height: calc(100%);
}

.chatGptChatMessageBody {
    width: 100%;
}

.copyAISaid .uiOKCancelCancel {
    background: transparent;
}

.keyboardEditDocumentError .copyAISaid {

}

.keyboardEditDocumentError .uiOKCancelOK {
    background: transparent;
}

.keyboardEditDocumentError .aiCommentError {
    margin-top: 6px !important;
    background: #63130a;
}

.chatGptChatMessageError .chatGptChatMessageHeader  {
    background: #63130a;
    display: none;
}

.chatGptChatMessageError .aiCheck {
    display: none;
}

.chatGptChatMessageError .keyboardEditDocument {
    background: #63130a;
}

.userSaidDel .uiOKCancelCancel {
    background: transparent;
}

.userSaidPause {
    display: flex;
    position: absolute;
    bottom: 0;
}
.userSaidPause .uiOKCancelCancel {
    background: rgb(181, 67, 26);
}

.keyboardAddButton .uiOKCancelCancel {
    background: rgb(49, 47, 102);
}


.aiComment {
    margin-top: 0px !important;
    background: #0f2733;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 80px)
}

.aiWorking {
    margin-top: 0px !important;
    background: #0f2733;
}


.aiTrace {
    margin-top: 6px !important;
    margin-bottom: 0px !important;
    background: rgb(2, 77, 87);
}

.aiTrace.aiHallucinated {
    margin-top: 0px !important;
    opacity: 0.7;
    background: rgb(49,0,0) !important;
}

.aiReply {
    margin-top: 6px !important;
}

.aiComment .uiOKCancelCancel {
    opacity: 0.5;
    background: transparent;
}

.aiCheck {
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: middle;
    padding-left: 4px;
}

.aiCheck svg {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.aiCheck div {
    display: inline-block;
}

.keyboardEditDocumentTextInline {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: pre-wrap;
}


.aiLinkWithTooltip {
    display: inline-block;
}

.aiLink {
    color: #6dad88;
    display: inline;
    cursor: pointer;
    white-space: normal;
    display: inline;
}


.keyboardEditDocumentTextInline  a {
    color: #6dad88;
    display: inline;
    cursor: pointer;
    white-space: normal;
}

.keyboardCode {
    padding-top: 10px;
}

.keyboardCode code {
    display: flex;
    color: #e5e5e5;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
}


.keyboardEditDocumentTextInline  .language-text {
    color: #1144cc !important;
}

.chatGptTooltip {
    position: absolute;
    left: 10px;
    bottom: 12px;
    color: #e7e7e7;
    font-size: 11px;
    opacity: .8;
    max-width: calc(100% - 10px - 120px)
}

.inputControlKeyboardButton.newTopicButton .uiOKCancelOK {
    background: rgb(49, 47, 102);
    width: 100px;
}

.aiCode {
    display: inline-block;
    font-family: apparat;
    font-size: 13px;
    font-weight: 500;
    white-space: pre-wrap;
    position: relative;
    overflow-wrap: break-word;
    background: rgb(255, 255, 255, 0.1);
    padding: 10px;
    margin-bottom: 5px;
    color: #f7f7f7;
    overflow-x: auto;
    word-break: break-all;
}

.aiCodeInline {
    display: inline;
    padding: 0px;
    margin: 0;
    background: none;
    color: #f7f7f7;
}

.aiPre .aiCode {
}

.chatGPT .inputControlSpectrumAnalyzer {
}

.chatGPT .inputControlSpectrumAnalyzer .micSpectrumAnalyzerContainer {
}

.messageSelection {
    max-width: 100%;
    width: 100%;
}

.chatGPT .messageSelectionModels .uiChatMarginBottom {
    height: calc(100% - 35px  - 40px);
}

.modelSelection {
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    display: flex;
    transform: translate(0, 40px);
    column-gap: 2px;
    margin-top: 2px;
    padding-bottom: 2px;
    min-height: 30px;
}

.modelSelectionModel {
    background: rgb(51, 51, 51);
    display: flex;
    flex-grow: 0;
    width: fit-content;
}

.modelSelectionModelSelected {
    background: rgb(59, 101, 201);
}

.modelSelection .swiper {
    width: auto;
    height: auto;
}

.modelSelection .swiper-slide {
    flex: 1 0 auto;
    width: auto;
}

.modelSelection .simpleIcon {
    width: 15px;
    height: 15px;
}

.modelSelection .simpleButton {
    height: 30px;
}

.simpleButton {
    display: flex;
    align-items: center;
    color: #e7e7e7;
    height: 40px;
    column-gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

}

.simpleButtonLabel {
    white-space: nowrap;
}




.keyboardRadioButton {
    width: 100%;
    display: flex;
    height: 40px;
    column-gap: 1px;
}

.keyboardRadioButtonIcon {
    display: flex;
    align-items: center;
    
}

.keyboardRadioButtonLabel {
    height: 40px;
    color: #e7e7e7;
    background: rgb(7, 100, 97);
    display: flex;
    align-items: center;
    max-width: 80px;
    justify-content: center;
    display: none;
}

.keyboardRadioButtonLeft {
    height: 40px;
    min-width: 40px;
    background: rgb(1, 154, 158);
}

.benxtAppMobile .keyboardRadioButtonLabel {
    display: none;
}

.benxtAppMobile .keyboardRadioButtonIcon {
    display: none;
}

.benxtAppMobile .keyboardRadioButtonOn {
    padding-left: 15px;
}

.benxtAppMobile .keyboardRadioButtonOff {
    padding-left: 15px;
}

.keyboardRadioButtonSmall .keyboardRadioButtonOff {
    display: none !important;
}

.keyboardRadioButtonSmall .keyboardRadioButtonOn {
    display: none !important;
}

.keyboardRadioButtonSmall {
    width: calc(46px * 3);
}

.benxtAppMobile .keyboardRadioButtonOff {
    padding: 0;
}

.benxtAppMobile .keyboardRadioButtonOn {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 65px;
}

.benxtAppMobile .keyboardRadioButtonOff {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 65px;
}

.benxtAppMobile .keyboardRadioButtonSmall .keyboardRadioButtonOn {

}
                  

.benxtAppMobile .keyboardRadioButtonSmall .keyboardRadioButtonIcon {
    display: flex;
}
    
.benxtAppMobile .keyboardRadioButtonRight {
    display: none;
}

.buttonz {
    display: flex;
    max-width: calc(100vw - 140px);
}

.keyboardRadioButtonRight {
    height: 40px;
    width: 40px;
    background: rgb(7, 100, 97);
}

.keyboardRadioButtonIcon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardRadioButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardRadioButtonIcon svg {
    height: 20px;
    width: 20px;
}

.keyboardRadioButtonOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    color: #e7e7e7;
    cursor: pointer;
    width: 80px;
}

.keyboardRadioButtonOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e7e7e7;
    height: 40px;
    width: 80px;
}

.keyboardRadioButtonUnselected {
    display: flex;
    column-gap: 1px;
    background: rgb(51, 51, 51);
    cursor: pointer;    
}

.keyboardRadioButtonSelected {
    background: rgb(59, 101, 201);
    display: flex;
    column-gap: 1px;
    cursor: pointer;
}

.keyboardRadioButtonOffButton {
    height: 40px;
    width: 40px;
    background: rgb(99, 19, 9);
}

.benxtAppMobile .keyboardRadioButtonLabel {
}

.benxtAppMobile .keyboardRadioButtonRight {

}

.benxtAppMobile .keyboardRadioButtonOn {
}

.benxtAppMobile .keyboardRadioButtonOff {

}


.chatGPTModelHeader {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-right: 10px;
}

.chatGPTModelHeaderClickable {
    cursor: pointer
}

.chatGPTModelHeaderAssistantView {
    background: rgb(59, 101, 201);
}

.keyboardEditDocumentText li p {
    display: inline;
}


.benxtAppAssistant .chatMessagesSlider .uiChatMarginBottom:first-child  {
    visibility: hidden;
}

.benxtAppAssistant .chatGptThreads .keyboardAddButton {
    display: none;
}

.threadDeleteButton {
    right: 0;
}

.threadDeleteButton .uiOKCancelCancel {
    background: transparent;
}

.chatGPT .threadDeleteButton .uiOKCancelCancel {

    background: #63130a;
}


.keyboardMenuItemRight {
    position: relative;
    display: flex;
    
}



.aiSaidImages  {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aiSaidImages img {
    width: 100%;
    object-fit: contain;
}

.toolUnselected {
    background: rgb(0, 26, 25);
}
    
.taskDescription {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    margin: 0;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.taskDescriptionLabel {
    max-width: calc(100% - 80px) !important;
    flex-grow: 1;
}

.taskDate {
    margin-right: 40px;
}

.taskTitle .keyboardMenuItemRight {
}

.taskLastTopic {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 90px);
    padding-left: 40px;
    padding-bottom: 10px;
    font-size: 13px;
}
.taskLastTopic .taskDate {
    margin-right: 10px;
}

.taskDate {
    font-size: 13px;
    display: flex;
    align-items: top;
    margin-top: 10px;
    justify-content: center;
    white-space: nowrap;
    margin-right: 5px;
}

.taskTopic {

}

.taskTitle {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    width: 100%;
    background: rgb(49, 47, 102);
    color: #e7e7e7;
}

.threadDeleteButtonConfirm {
    background: rgb(184, 38, 26);
    display: flex;
    right: 0px;
    white-space: nowrap;
    align-items: center;
    padding-left: 10px;
    color: #e7e7e7;
    cursor: pointer;
    height: 40px;
    overflow: visible;
}

.aiLinkImg {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
}

.tokenDollars {
    position: absolute;
    right: 0;
    color: white;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #3c3c3c;
    padding: 5px;
    
}

.sendError {
    position: absolute;
    width: 100%;
    background: #63130a;
    color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translate(0, 45px);
}


.inputControlInputEditorInput img {
    max-width: 100%;
}


.chatGPT .keyboardInputControlSpeech {
    display: none;
}

.attunewiseLayer .keyboardHeader {
    margin-bottom: 5px;
}

.chatHeaderFiller {
    color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}

.modelRadioButtonIcon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelRadioButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelRadioButtonIcon svg {
    height: 20px;
    width: 20px;
}

.modelRadioButtonOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    color: #e7e7e7;
    cursor: pointer;

}

.modelRadioButtonOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e7e7e7;
    height: 40px;
}

.modelRadioButtonUnselected {
    position: relative;
    display: flex;
    column-gap: 1px;
    background: rgb(49, 47, 102);
    cursor: pointer;
}

.modelRadioButtonSelected {
    position: relative;
    background: rgb(59, 101, 201);
    display: flex;
    column-gap: 1px;
    cursor: pointer;
}

.model.modelSubmenu {
    width: calc(100% - 25px);
    padding-left: 25px;
    position: relative;
}

.modelRadioButtonOffButton {
    height: 40px;
    width: 40px;
    background: rgb(99, 19, 9);
}

.modelRadioButtonRight {
    position: absolute;
    right: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modelRadioButtonRight div {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modelRadioButtonRight svg {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modelSpacer {
    height: 2px;
}

.aiHallucinated {
    opacity: 1.0;
    background: rgb(49,0,0) !important;
}

.aiNonHallucinated {
    opacity: 1.0;
    background: rgb(0, 102, 65) !important;
}

.chatGptChatMessageBody .aiUser  {
    background: rgb(10, 95, 145);
}


.haluExplainer {
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #999999;
    background: rgb(0, 26, 25);
}


.chatGptThreadsMenuTitle {
    display: flex;
    align-items: center;
    color: #e7e7e7;
    min-height: 40px;
    padding-left: 10px;
    width: calc(100% - 10px)
}

.questionRecall {
    background: rgb(0, 102, 65) !important;
}

.questionPrecision {
    background: rgb(49,0,0) !important;
}

.katex  * {
    font-family: apparat;
    font-weight: 400;
}

.katex .mathnormal {
    font-family: apparat;
}

.katex {
    font-family: apparat;
    font-weight: 400;
}

.katex-display {
    margin: 0;
}

.trainToggle {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: rgb(0, 26, 25);
    z-index: 2;
}

.uiOKCancelOofIcon svg {
    height: 40px !important;
    width: 40px !important;
}


.chatHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 1px;
}

.chatHeaderFiller {
    position: relative;
    flex-grow:1;
    background: rgb(49, 47, 102);
}

.chatHeaderSelectedModels {
    color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgb(49, 47, 102);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    width: 30px;
    color: transparent;
}

.chatBack .uiOKCancelOK {
    background: rgb(49, 47, 102);
}

.inputControlContainer {
    left: 0%;
    width: 100%;
}

.keyboardInstructionInput {
    width: 100%;
}


.modelCategory {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.modelCategoryMenu {
    display: flex;
    align-items: center;
    font-size: 16px;
    background: rgb(49, 47, 102);
    cursor: pointer;
    justify-content: space-between;

}

.modelCategoryOpen .modelCategoryMenu {

}

.modelCategoryIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.modelIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelIcon svg {
    height: 20px;
    width: 20px;
}

.model {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background: rgb(49, 47, 102);
    width: calc(100% - 0px);
    column-gap: 1px;
    cursor: pointer;
    position: relative;
}

.modelRadioButtonSelected {
    background: rgb(59, 101, 201);
}

.modelCategoryButtons {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    margin-top: 1px;
}

.modelLabel {
    color: #e7e7e7;
    padding-top: 10px;
    padding-bottom: 10px;
}

.modelVendorSelectionCountContainer {
    display: flex;
    height: 40px;
    align-items: center;
    column-gap: 10px;
}

.modelVendorSelectionCount {
    color: #e7e7e7;
}

.modelVendorCheck {
    display: none;
}

.modelVendorSelectionCountContainer svg {
    height: 25px;
    width: 25px;
}

.modelVendorLabel {
    padding-right: 4px;
    font-weight: 550;
}

.modelLeft {
    display: flex;
}

.modelRight {
    column-gap: 1px;
    display: flex;
}

.modelCategoryMenu .modelIcon svg {
    height: 20px;
    width: 20px;
}

.modelCategoryLeft {
    display: flex;
}

.modelCategoryRight {
    display: flex;
}

.imageChooser svg {
    width: 100%;
    height: 100%;
}

.imageChooser svg path {
    fill: #e7e7e7;
}

.imageChooser div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.imageChooser {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    flex-shrink: 0;

}

.newFineTune {
    width: 100%;
    position: absolute;
    top: 100px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.dialogField {
    background: rgb(49, 47, 102);
    display: flex;
    min-height: 45px;
    align-items: center;
    padding-left: 45px;
    width: calc(100% - 45px);
    color: #e7e7e7;
    column-gap: 2px;
    justify-content: space-between;
}

.dialogField .keyboardAddButton {
    position: static;
}


.inputControlKeyboardButton1.gear .uiOKCancelCancel {
    background: rgb(49, 47, 102);
}

.inputControlKeyboardButton1.gear svg {
    height: 35px !important;
    width: 35px !important;
}

.inputControlKeyboardButton1.gear svg {
    fill: #e7e7e7;
}

.modelsMenuVendorSelected .inputControlKeyboardButton1 .uiOKCancelCancel {
    background: rgb(59, 101, 201) 
}


.simpleCheckbox {
    color: #e7e7e7;
    font-weight: 500;
    background: rgb(51, 51, 51);
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    cursor: pointer;
}

.simpleCheckboxWithIcon {
    padding-left: 2px;
}

.simpleCheckboxSelected {
    background: rgb(59, 101, 201);
}

.simpleCheckboxIcon {
    height: 40px;
    width: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleCheckboxIcon div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleCheckboxIcon svg {
    height: 100%;
    width: 100%;
    fill: #e7e7e7;
}

.modelVision {
    position: absolute;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.modelVision div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.modelVision svg {
    height: 100%;
    width: 100%;
    fill: #aeaaae;
}

.modelsMenuVendor {
    height: 40px;
    width: 40px;
}

.modelPrice {
    color: #c7c7c7;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 11px;
    position: absolute;
    right: 70px;
    font-weight: 500;
}

.optionLabel {
    color: #e7e7e7;
    font-weight: 500;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    min-width: 55px;
    justify-content: flex-start;
}

.attunewiseContent {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    transition: left 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
    left: 0;
    overflow: visible;
}

.attunewiseTaskActive {
    left: -100%;
}

.attunewiseLayer {
    position: relative;
    height: 100%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    max-height: 100%;
    background: rgb(0, 25, 26);
    box-sizing: border-box;
}

.attunewiseLayerDiscussion {
    overflow: hidden;
}

.attunewiseLayerChat {
    position: absolute;
    left: 100%;
    top: 0px;
    overflow: hidden;
}

.discussionSearch  {
    min-height: auto;
    height: 40px;
}

.forModelsMenu {
    display: flex;
    min-width: 100%;
    height: 40px;
    justify-content: flex-end;
    position: absolute;
    background: rgb(0, 25, 26);
    overflow: hidden;
    z-index: 80;
}

.modelMenuWithTriggger {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
}

.popupHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: -1;
}

.modelMenuWithTriggger .keyboardAddButton {
    position: static;
}

.forModelsMenu .modelPrice {
    position: static;
    background: rgb(49, 47, 102);
    padding-right: 8px;
}


.chatHeaderSelectedModelIcons {
    display: flex;
    max-width: calc(40px * 5 + 4px);
    overflow-x: auto;
}

.chatHeaderSelectedModelIcon {
    min-width: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.chatHeaderSelectedModelIconSelected {
    background: rgb(59, 101, 201);
}

.forModelsMenu .chatHeaderSelectedModelIcons {
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 1px;
    background: rgb(49, 47, 102);
}

.simpleIcon {
    width: 20px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleIcon div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleIcon svg {
    height: 100%;
    width: 100%;
    fill: #e7e7e7;
}

.gear {
    display: none;
}

.messageBodySwiperContainer {
    width: 100%;
}

.messageBodyDotsContainer {
    background: rgb(2, 77, 87);
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
}

.messageBodyDotsContainer .slider {
    background: rgb(2, 77, 87);
}

.attunewiseDiscussionCal {
    position: relative;
    padding-top: 10px;
    margin-top: 2px;
    width: 100%;
    background: rgb(49, 47, 102);
    color: #e7e7e7;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.attunewiseDiscussionCal .usageCalendarViewChoice {
    margin-top: 2px;
    font-size: 13px;
    margin-bottom: 10px;
}


.discussionHeader {
    width: 100%;
    background: rgb(49, 47, 102);
    color: #e7e7e7;
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
    padding-bottom: 5px;
    padding-top: 15px;
}

.mainDots {
    width: calc(100% - 10px);
    margin-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.horizontalMessageLayout {
    width: 100%;
    background: rgb(2, 77, 87);
    color: #e7e7e7;
    box-sizing: border-box;
}

.leftColumn1 {
    display: flex;
}

.middleColumn1 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(100% - 80px);
}


.rightColumn1  {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.horizontalTextLayout{
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}

.benxtAppMobile .horizontalTextLayout {
    width: 100vw;
}

.aiJudge {
    background: #0f2733;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-top: 2px;
}


.modelJudgement {
    background: #0f2733;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}


.modelJudgementInline {
    background: #0f2733;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px
}

.modelJudgementText {
    margin-top: 10px;
    padding-bottom: 25px;
}

.modelJudgementHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    height: 40px;
}


.modelJudgementHeaderLeft {
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.modelJudgementHeaderRight {
    display: inline-flex;
    align-items: center;
}

.taskTitle .modelPrice {
    position: absolute;
    bottom: -10px;
    right: 44px;
}

.discussionHeader  {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.discussionHeader .modelPrice {
    position: static;
    height: auto;
    margin-right: 35px;
}



.codeCopy {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    max-height: 30px;
    width: 100%;
    margin-top: -20px;
}

.codeCopy .inputControlKeyboardButton1 .uiOKCancelCancel {
    background: transparent;
    transform: translate(8px, 8px);
}

.codeCopy .inputControlKeyboardButton .uiOKCancelOK {
    background: transparent;
    max-width: 90px;
}
 
.modelLegacy {
    text-decoration: line-through;
    text-decoration-color: #e0e0e0;
}


.claims {
    display: flex;
    width: 100;
    flex-direction: column;
    margin-top: 15px;
    row-gap: 5px;
}

.claim {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.claimCheck svg {
    display: flex;
    width: 20px;
    fill: #e7e7e7;
}


.forModelsMenu .bnContentPage {
    left: 0;
    top: 0;
    width: 40px;
}

.judgeWinnerMsg {
    background: #0f2733;
    padding: 10px;
    color: #e7e7e7;
    display: flex;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: column;
    width: 100%;
}

.judgesLine {
    margin-top: -10px;
    margin-left: -10px;
}

.judgeWinnerMsgText {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.judgeLosers {
    margin-top: 0px;
    margin-left: -10px;
}
.judgeLoser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.benxtAppMobile .judgeLoser {
    width: 80%;
}


.modelSelection1 .simpleButton {
    height: 30px;
    margin-left: -5px;
    margin-right: -5px;
    display: inline-flex;
}

.modelSelection1  {
    display: inline-flex;
}

.judgesLine {
    display: flex;
    transform: translate(-30px, 0);
    margin-bottom: 15px;
    font-weight: 550;
}

