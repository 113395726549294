.tempSlider {
    display: flex;
    background: rgb(49, 47, 102);
    color: #e7e7e7;
    align-items: center;
}

.tempLabel {
    padding-right: 15px;
    padding-left: 15px;
}

.slider {
    width: 55px;
    height: 100%;
    position: relative;
    cursor: pointer;
    background: rgb(49, 47, 102);
    position: relative;
}

.sliderButtonContainer {
    position: absolute;
    top: 0px;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
}

.sliderButtonContainerHigh .sliderValue {
    left: 5px;
    right: auto
}

.sliderButton {
    background: #e7e7e7;
    height: calc(100% - 8px);
    width: 4px;
    border-radius: 1px;
    position: absolute;
}

.sliderValue {
    left: auto;
    right: 5px;
    position: absolute;
    height: 100;
    color: #e7e7e7;
    font-weight: 500;
    display: flex;
    align-items: center;
}
