.usageCalendar {
    width: 100%;
    background: rgb(49, 47, 102);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    box-sizing: border-box;
}

.usageCalendarViewSelector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.usageCalendarViewChoice {
    padding: 10px;
    padding-left: 25px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
}

.benxtAppMobile .usageCalendarViewChoice {
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: center;
}

.usageCalendarViewChoiceSelected {
    background: rgb(59, 101, 201);
}

.mbsc-empty {
    display: none;
}

.mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell.mbsc-cal-today {
    color: white !important;
    background:  #20A1C4 !important;
}


.mbsc-cal-today :not(.mbsc-selected):hover .mbsc-cal-cell-txt{
    color: white !important;
    background: #11CEED !important;
}

.mbsc-windows .mbsc-cal-today:not(.mbsc-selected):hover .mbsc-cal-day-i {
    color: white !important;
    background: #11CEED !important;
}

.mbsc-windows .mbsc-cal-today:not(.mbsc-selected) .mbsc-cal-day-i .mbsc-cal-cell-txt {
    color: white !important;
    background: #20A1C4 !important;
}

.mbsc-windows .mbsc-cal-today:not(.mbsc-selected):hover .mbsc-cal-day-i .mbsc-cal-cell-txt {
    color: white !important;
    background: #11CEED !important;
}


.mbsc-windows .mbsc-cal-today:not(.mbsc-selected) .mbsc-cal-day-date {
    color: white !important;
    background:  #20A1C4;
    height: 100%;
}

.mbsc-windows .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-i.mbsc-cal-day-i {
    /*
    color: #666666;
    background-color: #B6EEFF;
   */
    height: 100%;
    color: white !important;
    background: rgb(37, 183, 68); 
}

.mbsc-windows .mbsc-cal .mbsc-cal-day .mbsc-selected:not(.mbsc-disabled):hover .mbsc-cal-cell-i:hover .mbsc-cal-day-i {
}


.mbsc-windows .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-txt {
    /*
    color: #666666 !important;
    background-color: #B6EEFF;
    color: white;
    background: rgb(37, 183, 68); 
   */
    color: white;
}


.mbsc-cal-day:hover {
    color: #666666 !important;
    background-color: #B6EEFF !important;
    height: 100%;;
}

.mbsc-windows .mbsc-cal-day-diff .mbsc-cal-day-i:hover {
    color: #666666 !important;
    background-color: #B6EEFF !important;
}

.mbsc-cal-day-marked:not(.mbsc-cal-today) .mbsc-cal-day-i {
    color: white !important;
    background: #f15a24;
    height: 100%;
}

.mbsc-cal-day-marked.mbsc-today .mbsc-cal-day-i {
    color: white !important;
    background:  #20A1C4;
    height: 100%;
}

.mbsc-cal-day-marked .mbsc-cal-cell-txt {
    color: white !important;
}

.mbsc-cal-day-marked .mbsc-cal-day-i:hover {
    background: #FF6e24;
}

.mbsc-cal-day-marked.mbsc-selected  .mbsc-cal-day-date {
    color: white !important;
    background: rgb(37, 183, 68) !important;
    height: 100%;
}

.mbsc-cal-day.mbsc-selected:not(.mbsc-disabled):hover .mbsc-cal-cell-i.mbsc-cal-day-i {
    /*background: #B6EEFF; */
    color: white !important;
    background: #11D316 !important;
}

.mbsc-cal-day.mbsc-selected:not(.mbsc-disabled):hover .mbsc-cal-cell-txt {
    /*background: #B6EEFF; */
    color: white !important;
    background: #11D316 !important;
}

.mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0 !important;
}

.mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-marks {
    height: 100%;
    display: none;
    transform: translate(0, calc(-100% + 14px));
    overflow: visible;
    width: 100%;
    background: rgb(37, 183, 68) !important;
    position: absolute;
    z-index: -1;
}

.mbsc-cal-day-markup {
}

.mbsc-cal-marks {
}

.mbsc-cal-mark {
    display: none;
}

.mbsc-event-list {
    display: none;
}

.mbsc-fr-w {
    font-family:  apparat, sans-serif;
    font-weight: 525;
    font-size: 14px;
}

.mbsc-ic-arrow-right6 {
    transform: scale(2, 2);
}

.mbsc-ic-arrow-left6 {
    transform: scale(2, 2);
}

.mbsc-cal-month {
    font-size: 16px;
}

.mbsc-cal-btn-w {
    height: 21px;
    display: flex;
    align-items: center;
}

.mbsc-cal-picker {
    background: rgb(49, 47, 102);
}
