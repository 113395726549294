.keyboardSettingStepsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.keyboardSettingStep2 {
    display: flex;
    align-items: center;
}

.keyboardSettingStep2TakeMeThere {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(55, 141, 206);
    font-size: 16px;
    background: rgb(0, 44, 44);
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.keyboardSettingSteps {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-right: 5px;
    min-width: 375px;
}

.keyboardSettingsStep {
    display: flex;
    align-items: center;
    font-size: 20px;
    width: 100%;
    white-space: nowrap;
    position: relative;
}

.settingStepText {
    font-weight: 300; 
    color: rgb(180, 180, 180);
}

.keyboardSettingsStepSubtext {
    font-weight: 300; 
    color: rgb(180, 180, 180);
    position: absolute;
    top: 50px;
    left: 20px;
    max-width: min(100vw - 60px, 350px);
    display: flex;
    font-size: 13px;
    white-space: normal;
}

.settingsStepBold {
    font-weight: 400;
    color: rgb(224, 224, 224);
}

.benxtAppDesktop .settingsStepBold {
}

.keyboardSettingsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: env(safe-area-inset-top);
}

.keyboardSettingsStepIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyboardSettingsStepIcon img {
    height: 40px;
    width: 40px;
}

.keyboardSettingsStepIcon div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyboardSettingsStepIcon svg {
    width: 40px;
    height: 40px;
}

.keyboardSettingsStepNumber {
    font-size: 15px;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 3px solid rgb(152, 229, 197);
    color: rgb(152, 229, 197);
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardSettingsTitle {
    margin-top: 35px;
}

.keyboardInstallationDoneSpacer1 {
    height: 15vh;
    width: 100%;
}

.keyboardInstallationDoneSpacer2 {
    height: 20vh;
    width: 100%;
}

.keyboardSettingsButtonSpacer {
    height: 10px;
}

.keyboardSettingsTitleIcon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardSettingsTitleIcon svg {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.appIconImage img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
}

.privacyBlurb {
    margin-top: 40px;
    color: rgb(152, 229, 197);
    width: calc(100% - 80px);
    font-size: 12px;
}
