
html {
  height: 100%;
  width: 100%;    
  position: absolute;
  bottom: 0;
  margin: 0;
  font-family:  apparat, sans-serif;
  -webkit-text-size-adjust: 100%;
}

body, #root{
  height: 100%;
  width: 100%;    
  position: fixed;
  margin: 0;
  font-family: apparat, sans-serif;
  scrollbar-width: thin;
  height: 100%;
  width: 100%;
  font-size: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
}

.uiClientBlockInput {
    touch-action: none;
}


body::-webkit-scrollbar{
    display: none;
}



:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}
