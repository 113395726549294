.bnLabel1 {
    font-weight: 400;
    display: flex;
    font-size: 20px;
    color: rgb(241, 241, 241);
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
}

.bnAnimatedLabel {
    height: 20px;
    overflow: hidden;
}

.bnAnimatedLabel .bnLabel1 {
    height: 20px;
    margin-bottom: 0px;
}

.bnLabel2 {
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(115, 153, 157);
    margin-bottom: 2px;
}

.bnErrorLabel {
    display: flex;
    align-items: center;
    height: 30px;
    color: rgb(184, 38, 26);
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
}


.bnLabel3 {
    display: flex;
    align-items: center;
    color: rgb(2, 91, 125);
}

.bnFieldLabel3 {
    align-items: flex-start;
}

.bnFieldLabel3Label {
    min-width: 20px;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.bnLabel3Icon div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnLabel3Icon svg {
    height: 20px;
    width: 20px;
}

.bnOrangeLabel {
    color:  rgb(245, 90, 0);
    font-weight: bold;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.bnOrangeLabelValue {
    color:  rgb(245, 90, 0);
    font-weight: bold;
}

.bnFieldTypeLabel {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    color: #6d6d6d;
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 10px;
}

.bnFieldLabelText {
    color: #6d6d6d;
}

.bnFieldLabel {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 25px;
    width: calc(100% - 30px);
}

.bnBookingStatusRequested {
    color: rgb(0, 190, 0);
}

.bnBookingStatusScheduled {
    color: rgb(0, 190, 0);
}

.bnBookingStatusBooked {
    color: rgb(0, 190, 0);
}

.bnBookingStatusCanceled {
    color: rgb(184, 38, 26);

}

.bnBookingStatusCompleted {
    color:  rgb(245, 90, 0);

}

