.bnSplash {
    background: rgb(242, 252, 255);
    height: 100%;
    width: 100%;
}


.bnSplashTitle {
    position: absolute;
    top: 30%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 42px;
    font-weight: bold;
    letter-spacing: -5px;
    transform: scale(1, 1.2);
    display: none;
}

.bnSplashTitleBE {
    color: rgb(94, 94, 94);
}

.bnSplashTitleNXT {
    color: rgb(255, 0, 200);
}


.bnSplashLogo {
    position: absolute;
    top: 30%;
    display: flex;
    width: 100%;
    justify-content: center;
}

.bnSplashLogo svg {
    height: 45px;
}
