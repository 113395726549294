.uiIcon {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiIconSvg svg {
    height: 45px;
    width: 45px;
    fill: rgb(250, 250, 250);
}

