.bnForm {
    padding: 5px;
    padding-right: 5px;
    width: calc(100% - 10px);
    max-width: 600px;
}

.benxtAppMobile .bnForm {
    max-width: auto;
}

.bnFormFieldSeparator {
    height: 10px;
    width: 100%;
}

.bnFormFieldsContainer {
    margin: 1px;
    width: calc(100% - 2px);
    overflow: hidden;
}

.bnFormField {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bnFormFields {
    width: 100%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.bnFormButton {
    width: calc(100% - 30px);
    padding: 15px;
    background: white;
    padding-top: 10px;
    padding-bottom: 0;
}

.bnFormButtons {
    display: flex;
}
