.keyboardLogin {
    position: relative;
    height: 100%;
    width: 100%;
}

.keyboardGetStartedPage {
    top: 0;
    left: 0;
    position: relative;
    height: 100%;
    width: 100%;
}

.keyboardGetStartedContent .bnButton {
}

.keyboardGetStartedMiddle {
    width: calc(100% - 10px);
    position: absolute;
    top: 180px;
    height: 200px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 30px;
}

.keyboardGetStartedContent {
    position: relative;
    height: 100%;
    width: 100%;
    background: rgb(0, 26, 27);
}

.keyboardGetStartedButton {
    position: absolute;
    top: 385px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.keyboardGetStartedLogo {
    position: absolute;
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #e7e7e7;
    font-size: 20px;
    height: 107px;
}

.keyboardLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    justify-content: flex-end;
    height: 100%;
}

.keyboardLogoImg img {
    max-height: 81px;
    object-fit: contain;
}

.keyboardLogo svg {
    width: 48px;
}

.keyboardLogoImg {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signInCreateAccount .keyboardLogo img {
    height: 60px;
}

.signInVerificationCode .keyboardLogo img {
    height: 60px;
}

.keyboardGetStartedBack {
    position: absolute;
    top: 10px;
    left: 10px;
}

.bnSignInFields {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.keyboardOof {
    box-sizing: border-box;
    margin-top: 30px;
}

.keyboardOof .homeButtonButton {
    background: #63130a;
}

.referralCodeInput {
    margin-top: 40px;
}

.redirectToAppStore {
    color: #e7e7e7;
    display: flex;
    width: 100%;
    justify-content: center;
}

.bnReferralCodeContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.bnReferralCode {
    width: 50%;
}

.signInCreateAccount .keyboardGetStartedButton {
    top: 435px;
}

.signInCreateAccount .keyboardOof {
    margin-top: 20px;
}


.googleSignInButtons {
    width: 200px;
    margin-bottom: 15px;

}
.googleSignInButtons .homeButton {
    font-weight: normal;
}
