.keyboardHome {
    width: 100%;
}

.homeButton {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
}

.homeButtonLabels {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
}

.homeButtonLabel {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(115, 153, 157);
}

.homeButtonSublabel {
    font-size: 12px;
    font-style: italic;
    color: #ff3d00;
}

.homeButtonButton {
    color: #e7e7e7;
    display: flex;
    width: 100%;
    height: 40px;
    background: rgb(0, 147, 132);
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.homeButtonButtonIcon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeButtonButtonLabel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeButtonButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeButtonButtonIcon svg {
    width: 20px;
    height: 20px;
    fill: #e7e7e7;
}

.keyboardHomeBottom {
    margin-top: 30px;
    width: 100%;
}

.keyboardHomeButtons {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.keyboardHomeSavedButtons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
}

.keyboardHomeSavedButtonsList {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
}

.savedButtonCategory {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.savedButtonCategoryMenu {
    display: flex;
    align-items: center;
    font-size: 16px;
    background: #64618c;
    cursor: pointer;
}

.savedButtonCategoryOpen .savedButtonCategoryMenu {
    font-weight: bold;
}

.savedButtonCategoryIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.savedButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonIcon svg {
    height: 20px;
    width: 20px;
}

.savedButton {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background: rgb(93, 91, 132);
    width: calc(100% - 0px);
    column-gap: 1px;
}

.savedButtonCategoryButtons {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    margin-top: 1px;
}

.savedButtonLabel {
    padding-top: 10px;
    padding-bottom: 10px;
}

.savedButtonLeft {
    display: flex;
}

.savedButtonRight {
    column-gap: 1px;
    display: flex;
}

.savedButtonCategoryMenu .savedButtonIcon svg {
    height: 20px;
    width: 20px;
}

.savedButtonDelete .uiOKCancelCancel {
    background: rgb(95, 18, 10);
}

.savedButtonShare .uiOKCancelCancel {
    background: #3c65c9;
}

.savedButton .savedButtonIcon svg {
    fill: #e7e7e7;
}

.keyboardHomeHeader {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

.keyboardHomeHeader .keyboardHeaderTitle {
    width: 100%;
    display: flex;
    justify-content: center;
}

.keyboardHomeTop {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.keyboardHomeShape {
    pointer-events: none;
    position: relative;
    width: calc(100% - 20px);
    overflow: hidden;
}

.keyboardHomeShape svg {
    width: 100%;
}

.keyboardHomeStats {
    top:0 ;
    width: 100%;
    height: 100%;
    position: absolute;
}

.keyboardHomeStat {
    position: absolute;
    display: flex;
    flex-direction: column;
}

.keyboardHomeStatLabel {
    font-weight: bold;
    text-transform: uppercase;
    color: #e5e0bf;
    font-size: 11px;
}

.keyboardHomeStatValue {
    color: #e7e7e7;
    font-size: 42px;
    font-weight: 500;
}

.statAvailable {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translate(36.5%, -7%);
}

.statAvailable .keyboardHomeStatLabel {
    font-size: 16px;
}

.statUsed {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translate(15%, 0%);
}

.statPurchased {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translate(15%, 30%);
}

.openAIStatusDescriptionOpenAI {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 550;
    fill: #e7e7e7;
    text-anchor: middle;
    transform: translate(0, -18px);
}

.openAIStatusDescription {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 550;
    fill: #e7e7e7;
    text-anchor: middle;
}

.openAIStatusNone .openAIStatusDescription {
    fill: rgb(0, 147, 132);;
}

.openAIStatusNone .openAIStatusDescriptionOpenAI {
    fill: rgb(0, 147, 132);;
}

.benxtAppMobile .openAIStatusDescription {
}

.statPurchased .keyboardHomeStatLabel {
    font-size: 11px;
}

.statPurchased .keyboardHomeStatValue {
    font-size: 22px;
}

.statUsed .keyboardHomeStatLabel {
    font-size: 11px;
}

.statUsed .keyboardHomeStatValue {
    font-size: 22px;
}

.keyboardHomeAccountSpacer0 {
    margin-bottom: 30px;
}

.keyboardHomeAccountSpacer1 {
    height: 30px;
}

.keyboardHomeAccountSpacer2 {
    height: 30px;
}
.keyboardHomeAccountDelete {
    width: 100%;
}

.keyboardHomeAccountDelete .homeButtonButton {
    background: #63130a;
}
.keyboardHomeAccountDeleteConfirm .homeButtonButton {
    background: rgb(184, 38, 26);
}

.keyboardHomePhone {
    width: 100%;
    display: flex;
    row-gap: 1px;
    flex-direction: column;
    align-items: flex-end;
}

.keyboardHomePhone .inputControlKeyboardButton .uiOKCancelOK {
    margin-right: 10px;
    background: #3c65c9;
}

.keyboardHomeWordPacksLowIcon svg {
    height: 20px;
    width: 20px;
}

.keyboardHomeWordPacksLow {
    width: calc(100% - 40px);
    margin-left: 10px;
    margin-right: 10px;
    background: rgb(53, 91, 180);
    display: flex;
    padding: 10px;
    align-items: flex-start;
}

.heroMaskLeft {
    fill: #086461;
    opacity: .92;
}

.heroMaskMiddle {
    fill: #086461;
    opacity: .92;
}

.keyboardHomeWordPacksLowStatus .heroMaskMiddle {
    fill: rgb(53, 91, 180);
}


.heroMaskRight {
    fill: #95e2c2;
}

.wordPackBuyButtonLow .homeButtonButton {
    background: rgb(53, 91, 180);
}

.keyboardHomeWordPacksLowIcon {
    display: flex;
    max-height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
}

.keyboardHomeWordPacksLowIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardHomeWordPacksLowMessage {
    padding-left: 10px;
    display: flex;
}

.openAIStatusMajor {
    fill: rgb(99, 19, 9);
}

.openAIStatusMinor {
    fill: rgb(205, 87, 60);
}

.keyboardHomeKeyboardActivate {
    background: rgb(53, 91, 180);
    display: flex;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    position: relative;
}

.keyboardHomeKeyboardActivate img {
    width: calc(100% - 50px);
    padding-bottom: 10px;
    padding-right: 10px;
}

.keyboardHomeKeyboardActivate  .keyboardHomeWordPacksLowIcon {
    align-items: flex-start;
    padding: 10px;
    padding-top: 0px;
}

.keyboardHomeKeyboardActivateBlurb {
    position: absolute;
    right: 5px;
    top: 10px;
    width: 45%;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.keyboardPage {
    width: 100%;
    height: cacl(100% - 40px);
    margin-top: 40px;
}
    
.savedButtonTabsContainer {
    display: flex;
    flex-direction: column;
}

.savedButtonTabs {
    display: flex;
    width: 100%;
    background: #64618c;
    margin-bottom: 1px;
}

.savedButtonTab {
    display: flex;
    width: 50%;
    height: 40px;
    align-items: center;
    background: rgb(39, 52, 69);
    cursor: pointer;
}

.savedButtonTabSelected {
    background: #64618c;
}

.savedButtonTabIcon  {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonTabIcon  div {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonTabIcon svg {
    height: 20px;
    width: 20px;
}

.savedButtonTabLabel {
    height: 100%;
    display: flex;
    align-items: center;
}

.wordPackBuyButton {
}

.benxtAppNative .wordPackBuyButton {
    display: flex;
}

::-webkit-scrollbar {
    display: none;
}

.inputReferralCode .homeButtonSublabel {
    color: #e7e7e7;
}

.keyboardHome input:disabled {
}

.homeButtonRight {
    display: flex;
    justify-content: center;
    width: 100%;
}

.homeButtonRightBusy {
    margin-top: 20px;
}

.homeButtonRight svg {
    height: 182px;
    width: 182px;
}

.intellikeyLinks {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 10px);
    display: flex;
    column-gap: 8px;
    margin-top: -45px;
    margin-bottom: 40px;
}

.benxtAppMobile .intellikeyLinks {
    margin-top: calc(-7.5vw);
    column-gap: 1px;
}

.benxtAppAssistant .intellikeyLinks {
    display: none;
}

.benxtAppMobile .intellikeyLinks svg {
    height: calc(6.5vw);
}

.intellikeyLinks svg {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.accountKeyboardSettings {
    width: calc(100% - 20px);
    margin-left: 10px;
}

.accountKeyboardSettingsCheckboxes {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;
}


.keyboardCheckbox {
    width: 100%;
    display: flex;
    height: 40px;
    column-gap: 1px;
}

.keyboardCheckboxLabel {
    height: 40px;
    flex-grow: 1;
    color: #e7e7e7;
    background: rgb(7, 100, 97);
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.keyboardCheckboxLeft {
    height: 40px;
    min-width: 40px;
    background: rgb(1, 154, 158);
}

.keyboardCheckboxRight {
    height: 40px;
    width: 40px;
    background: rgb(7, 100, 97);
}

.keyboardCheckboxOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: #e7e7e7;
    background: rgb(59, 101, 201);
    width: 80px;
    cursor: pointer;
}

.keyboardCheckboxOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7e7e7;
    background: rgb(51, 51, 51);
    height: 40px;
    width: 80px;
}

.keyboardCheckboxUnselected {
    display: flex;
    column-gap: 1px;
}

.keyboardCheckboxSelected {
    display: flex;
    column-gap: 1px;
}

.keyboardCheckboxOffButton {
    height: 40px;
    width: 40px;
    background: rgb(99, 19, 9);
}

.apiSpec {
    margin-left: 10px;
    margin-top: 15px;
    width: calc(100% - 20px);
}

.apiSpecTitle {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(115, 153, 157);
}

.apiSpecUrl {
    background: gray;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.gpt {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
}
.gpt > div {
    display: flex;
    height: 100%;
    width: 100%;
}

.gpt webview {
    margin-top: 0px;
    height: calc(100% - 0px);
    width: 100%;
}

.gpt webview a {
    display: inline-block !important;
    visibility: visible !important;
    background: red;
    min-width: 100px;
    min-height: 100px;
}

.gptPage .bnBackButton {
    position: absolute;
    right: 5px;
}

.gptBack {
    position: absolute;
    top: 2.5px;
    left: calc(100% - 45px);
    max-height: 40px;
}

.homeButton .keyboardRadioButtonOn {
    min-width: 100px;
}

.homeButton .keyboardRadioButtonOff {
    min-width: 100px;
}

.homeGPT .keyboardHomeStats {
    display: none;
}

.attunewiseHomeButtons {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}


.attunewiseUsageCal {
    position: relative;
    background: rgb(0, 26, 25);
    margin-top: 0px;
    color: #e7e7e7;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 100%;
}

.attunewiseUsage {
    width: 100%;
    margin-top: 1px;
    row-gap: 1px;
    display: flex;
    background: rgb(0, 26, 25);
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 10px;
}

.attunewiseTotalUsage {
    margin-top: 1px;
    background: rgb(49, 47, 102);
    height: 40px;
    width: 100%;
    color: #e7e7e7;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    position: relative;
}

.attunewiseTotalUsageValue {
    display: flex;
    align-items: center;
}

.attunewiseUsageView {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.benxtAppNative.benxtAppMobile .attunewiseUsageView {
    margin-bottom: calc(env(safe-area-inset-bottom));
    height: calc(100% - 50px);
}


