.bnClient.benxtAppDesktop {
    display: flex;
    width: 100%;
    justify-content: center;
    background: #002C2B;
    height: 100%;
    overflow: hidden;
}

.benxtAppDesktop .keyboardSplash {
    position: absolute;
    font-family: Apparat;
}


/*
.benxtAppDesktop  .bnPageBody {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}
.benxtAppDesktop  .keyboardHome {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}

.benxtAppDesktop .keyboardLogin {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}

.benxtAppDesktop .keyboardWordPackContent {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}
*/

.bnAppContent {
    padding-top: calc(env(safe-area-inset-top));
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: 100%;
}

.benxtAppDesktop .bnAppContent {
    width: 100%;
    height: 100%;
    padding: 1px;
    overflow-x: hidden;
    width: 600px;
}

.benxtAppDesktop .keyboardApp {
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: relative;
}

.benxtAppDesktop .keyboardAppContent {
}


.keyboardApp {
    position: absolute;
    height: 100%;
    width: calc(100% - env(safe-area-inset-left));
}

.keyboardAppContent {
    height: 100%;
    width: 100%;
}

.bnPhoneSignIn .bnForm {
    padding-top: 0;
}


#recaptcha-fun {
    display: none;
}

iframe {
    top: env(safe-area-inset-top);
}

.keyboardSignIn {
    margin-top: calc(60px + 30px);
}

.keyboardVerificationCode {
    margin-top: calc(60px)
}

.keyboardSplashLogo img {
    height: 60px;
    width: 60px;
}

.keyboardSplash {
    position: fixed;
    background: rgb(4, 28, 35);
    color: #e7e7e7;
    font-family: -apple-system, apparat;
    font-weight: 500;
    font-size: 16px;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 99;
}

.benxtAppDesktop .keyboardSplash {
    max-width: 700px;
}

.keyboardSplashContent {
    position: absolute;
    top: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.keyboardLoginInProgressPage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
}

