.bnClient {
    height: 100%;
    width: 100%;
    background: rgb(0, 26, 25);
}

.benxtAppWindows {
    font-family: arial !important;
}

.benxtAppWindows::scrollbar {
    display: none;
}
