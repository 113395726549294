.uiOKCancel {
    display: flex;
    align-items: center;
    height: 40px;
    column-gap: 1px;
    position: relative;
    font-weight: 500;
}

.uiOKCancelOof {
    background: rgb(184, 38, 26);
    height: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: 0;
}

.uiOKCancelOof:hover {
    background: #CA0000;
}

.uiOKCancel img {
    height: 40px;
    width: 40px;
}

.uiOKCancelOofIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelOofIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputControlKeyboardButton1 .uiOKCancelOofIcon svg {
    height: 40px !important;
    width: 40px !important;
    fill: #e7e7e7;
}

.uiOKCancelOofLabel {
    padding-right: 20px;
    white-space: nowrap;
}

.uiOKCancelCancel {
    background:  rgb(99, 19, 9);
    cursor: pointer;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelCancelIcon {
    font-size: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelCancelIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelCancelIcon svg {
    width: 100%;
    height: 100%;
    fill: white;
}

.uiOKCancelCancel:hover {
    background: #CA0000;
}

.uiOKCancelOK {
    height: 40px;
    width: 120px;
    background: rgb(1, 160, 144);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.uiOKCancelOK:hover {

}

.uiOKCancelOKIcon {
    height: 40px;
    width: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelOKIcon div {
    height: 40px;
    width: 40px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uiOKCancelOKIcon svg {
    fill: #e7e7e7;
}

.uiOKCancelOKLabel {
    color: #e7e7e7;
    display: flex;
    align-items: center;
    font-size: 16px;
    white-space: nowrap;
    width: 70px;
    padding-right: 10px;
}

@keyframes spinx {
    100% {
        transform: rotate(360deg);
    }
}

.inputControlKeyboardButton1 .uiOKCancelBusy .SVG-Nano-001 {
    height: 20px !important;
    width: 20px !important;
}

.inputControlKeyboardButton .uiOKCancelBusy .SVG-Nano-001 {
    height: 20px !important;
    width: 20px !important;

}

.uiOKCancelBusy svg {
}

.uiOKCancelBack {
    background: rgb(213, 102, 88);
    cursor: pointer;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    margin-right: 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelBack div {
    font-size: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiOKCancelBack svg {
    width: 100%;
    height: 100%;
    fill: white;
}

.uiOKCancelBack:hover {
    background: rgb(238, 102, 78);

}
